const common = {
  book: "Book Now",
  menu: "Menu",

  navigation: {
    home: "Home",
    services: "Services",
    about: "About",
    access: "Access",
    contact: "Contact",
    prices: "Prices",
  },
};
export default common;
