const booking = {
  title: "Book your stay",
  description: "Welcome to the Refuge de Loriaz. Please fill in the form below to book your stay.",
  confirmation: {
    title: "Your reservation is confirmed!",
    message:
      "Thank you for your reservation at the Refuge de Loriaz. You will soon receive a confirmation email with all the details of your stay.",
  },
  buttons: {
    home: "Back to home",
    contact: "Contact us",
  },
};

export default booking;
