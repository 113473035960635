const common = {
  book: "Réserver",
  menu: "Menu",
  navigation: {
    home: "Accueil",
    services: "Services",
    about: "À propos",
    access: "Accès",
    contact: "Contact",
    prices: "Tarifs",
  },
};

export default common;
